<template>
<!-- 菜单管理 -->
  <div class="main">
    <div class="condition-filtrate-box">
      <div class="left-item">
        <span style="margin-right:10px;font-size:14px;color:#333;">菜单名称</span>
        <el-input
          class="select-width id-code-width"
          size="medium"
          v-model="queryForm.searchValue"
          placeholder="菜单名称、编码" />
        <span style="margin: 0 10px;font-size:14px;color:#333;">状态</span>
        <el-select class="select-width" size="medium" v-model="queryForm.status" placeholder="全部">
          <el-option
            v-for="(item,v) in statusList"
            :key="v"
            :label="item.label"
            :value="Number(item.value)"
          ></el-option>
        </el-select>
      </div>

      <div class="right-item">
        <el-button type="primary" size="small" @click="queryUserInfo">查询</el-button>
        <el-button type="primary" size="small" @click="addUserInfo">添加顶部菜单</el-button>
      </div>
    </div>

    <el-table height="100%" :data="tableData"  row-key="id" default-expand-all border>
      <el-table-column prop="name" label="菜单名称" width="220"></el-table-column>
      <el-table-column label="操作" width="180" align="center">
        <template slot-scope="{ row }">
          <el-link type="primary" @click="editTableFun(row)">编辑</el-link>
          <el-link type="danger" @click="deleteTableFun(row)">删除</el-link>
          <el-link type="primary" @click="addChildUserInfo(row)">添加子菜单</el-link>
        </template>
      </el-table-column>
      <el-table-column prop="permission" label="菜单ID" width="150"></el-table-column>
      <el-table-column prop="des" label="菜单描述" width="150"></el-table-column>
      <el-table-column prop="url" label="菜单URL" width="200"></el-table-column>
      <el-table-column prop="iconCls" label="菜单图标"></el-table-column>
      <el-table-column prop="seq" label="菜单序号"></el-table-column>
      <el-table-column prop="type" label="菜单类型" :formatter="typeFormatter"></el-table-column>
      <el-table-column prop="status" label="状态">
        <template slot-scope="{ row }">
          <el-tag size="small" v-if="row.status == 1">启用</el-tag>
          <el-tag size="small" type="danger" v-else-if="row.status == 0">停用</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="updUserName" label="最后更新人"></el-table-column>
      <el-table-column prop="updTime" label="最后更新时间" width="200"></el-table-column>
    </el-table>

    <!-- <div class="content-page">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageObj.pageNumber"
        :page-size="pageObj.pageSize"
        :total="pageObj.total"
        :page-sizes="[10, 20, 30, 50, 100]"
        layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
    </div> -->

    <el-dialog title="菜单编辑" :visible.sync="dialogFormVisible" :close-on-click-modal="false" width="600px" >
      <el-form :model="form" ref="addForm">
        <!-- {{form.parentId}} -->
        <el-form-item label="父级菜单:" v-if="form.parentId != 0" label-width="120px" prop="parentId" :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
          <el-cascader
            v-model="form.parentId"
            placeholder="输入关键字进行过滤"
            style="width:300px;"
            :options="cdList"
            :props="{ checkStrictly: true ,label:'name',value:'resId'}"
            :show-all-levels="false"
            filterable></el-cascader>
           <!-- <el-popover
              placement="bottom"
              width="300"
              trigger="click">
              <el-input
                style="width:320px;"
                slot="reference"
                placeholder="输入关键字进行过滤"
                v-model="form.parentName">
              </el-input>
              <el-tree default-expand-all :filter-node-method="filterNode" ref="tree" :data="tdata" :props="defaultProps" @node-click="handleNodeClick"></el-tree>
            </el-popover>  -->
        </el-form-item>
        
        <el-form-item label="菜单名称:" label-width="120px" prop="name"  :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
          <el-input v-model="form.name" maxlength="10" placeholder="菜单名称">
          </el-input>
        </el-form-item>
        <el-form-item label="菜单URL:" label-width="120px" prop="url" :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
          <el-input v-model="form.url"  placeholder="菜单URL地址">
          </el-input>
        </el-form-item>
        <el-form-item label="权限编码:" label-width="120px" prop="permission" :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
          <el-input v-model="form.permission"  placeholder="权限编码">
          </el-input>
        </el-form-item>
        <el-form-item label="菜单图标:" label-width="120px" prop="iconCls" >
          <el-input v-model="form.iconCls"  placeholder="菜单图标编码">
          </el-input>
        </el-form-item>
        <el-form-item label="菜单描述:" label-width="120px" prop="des" >
          <el-input  v-model="form.des" maxlength="20" placeholder="菜单描述"></el-input>
        </el-form-item>
        <el-form-item label="菜单类型:" label-width="120px" prop="type" :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
          <el-radio-group v-model="form.type">
            <el-radio label="1">菜单</el-radio>
            <el-radio label="2">功能</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="排序号:" label-width="120px" prop="seq" :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
           <el-input-number v-model="form.seq"  :min="1"  label=""></el-input-number>
        </el-form-item>
        <el-form-item label="状态:" label-width="120px" prop="status" :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
          <el-radio-group v-model="form.status">
            <el-radio label="1">启用</el-radio>
            <el-radio label="0">停用</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogSubmit('addForm')">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import { exteriorUrl } from '@/api';
import constants from "../../common/constants";
import { getAllSysResTreeList,getSysResTreeList,menuInfo,menuSave,menuUpdate,menuDel  } from '../../api/SystemManagement/index.js';
import { mapState } from 'vuex';
const formObj = {//表单
  parentName:"",
  parentId:"",
  name:"",
  url:"",
  permission:"",
  iconCls: "",
  des:"",
  type:"1",
  seq:1,
  status:'1'
};
const typeList = [
    { value: "1", label: "菜单" },
    { value: "2", label: "功能" }
];

export default {
  name: "menuManage",
  data() {
    return {
      statusList: constants.USER_STATUS2,
      queryForm: {
        searchValue:'',
        status:''
      },
      tableData: [], //表单数据
      pageObj:{
        pageNumber: 1,
        pageSize: 20,
        total: 0,
      },
      dialogFormVisible:false,
      dialogFormVisibleType:'add',
      form:formObj,
      cdList:[],
      tdata: [{
          label: '一级 1',
          children: [{
            label: '二级 1-1',
            children: [{
              label: '三级 1-1-1'
            }]
          }]
        }, {
          label: '一级 2',
          children: [{
            label: '二级 2-1',
            children: [{
              label: '三级 2-1-1'
            }]
          }, {
            label: '二级 2-2',
            children: [{
              label: '三级 2-2-1'
            }]
          }]
        }, {
          label: '一级 3',
          children: [{
            label: '二级 3-1',
            children: [{
              label: '三级 3-1-1'
            }]
          }, {
            label: '二级 3-2',
            children: [{
              label: '三级 3-2-1'
            }]
          }]
        }],
        defaultProps: {
          children: 'children',
          label: 'label'
        }

    }
  },

  computed: {
    ...mapState({
      isolationList: state => state.user.isolationList,
    }),
  },

  watch: {
      // 'form.parentName'(val) {
      //   this.$refs.tree.filter(val);
      // },
      // 'form.parentId'(val) {
      //   this.form.parentName = this.cdList.find();
      // }
  },

  created() {
    this.queryUserInfo();
    this.cdListFun()
  },

  methods: {
    cdListFun(){
      getAllSysResTreeList().then((res)=>{
        let { code, data, msg } = res.data;
        if(code == 200){
          this.cdList = data;
          console.log("🚀 ~ file: menuManage.vue ~ line 177 ~ getAllSysResTreeList ~ this.cdList", this.cdList)
        }
      })
    },
    // handleNodeClick(data) {
    //     console.log(data);
    // },
    // filterNode(value, data) {
    //     if (!value) return true;
    //     return data.label.indexOf(value) !== -1;
    // },
    timeFormatter(row, column) {
      if(!row.lastModifiedDate) return '';
      let {year:y,monthValue:m,dayOfMonth:d,hour:hh,minute:mm,second:ss} = row.lastModifiedDate;
      let val = `${y}-${m}-${d} ${hh>9?hh:'0'+hh}:${mm>9?mm:'0'+mm}:${ss>9?ss:'0'+ss}`
      return val
    },
    typeFormatter(row){
      if(!row.type) return '';
      let obj = typeList.find((item)=>{
        return item.value == row.type;
      })
      return obj.label
    },
    getUserInfo(){
      let params = Object.assign({},this.queryForm,this.pageObj);
      getSysResTreeList({params}).then((res)=>{
        let { code, data, msg } = res.data;
        if(code == 200){
          this.tableData = data;
          console.log(this.tableData);
          this.pageObj.total = Number(data.totalNumber);
        }
      })
    },
    //查询
    queryUserInfo() {
      this.getUserInfo();
    },
    //新增
    addUserInfo() {
      this.dialogFormVisible = true;
      this.dialogFormVisibleType = 'add';
      this.form = Object.assign({}, formObj,{parentId: "0"});
    },
    addChildUserInfo(row) {
      this.dialogFormVisible = true;
      this.dialogFormVisibleType = 'add';
      this.form = Object.assign({}, formObj,{parentId: row.id});
    },
    //表单新增
    dialogSubmit(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitFormFun();
        } else {
          return false;
        }
      });
    },
    submitFormFun(){
      if(Object.prototype.toString.call(this.form.parentId) === '[object Array]'){
        this.form.parentId = this.form.parentId[this.form.parentId.length-1]
      }
      let params = Object.assign({},this.form);
      var API = this.dialogFormVisibleType == 'add'?menuSave:menuUpdate;
      API(params).then((res)=>{
        let { code, data, msg } = res.data;
        if(code == 200){
          this.dialogFormVisible = false;
          this.$message({
            type: 'success',
            message: '保存成功!'
          });
          this.queryUserInfo();
        }
      })
    },

    //table操作
    //修改
    editTableFun(row){
      this.dialogFormVisible = true;
      this.dialogFormVisibleType = 'set';
      this.form = Object.assign({},row);
      console.log(this.form );
    },

    //删除
    deleteTableFun(row){
      this.$confirm(`确定要删除【${row.name}】吗？</br><p style="color:red;">删除父级同时会删除子级菜单</p>`, '提示', {
          dangerouslyUseHTMLString: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          menuDel({id:row.id}).then((res)=>{
            let { code, data, msg } = res.data;
            if(code == 200){
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
              this.queryUserInfo();
            }
          })

        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
    },

    //分页变化
    handleSizeChange(val) {
      this.pageObj.pageSize = val;
      this.getUserInfo();
    },
    handleCurrentChange(val) {
      this.pageObj.pageNumber = val;
      this.getUserInfo();
    },


  }
}
</script>

<style scoped lang="scss">
@import "../../static/css/condition-filtrate.scss";

.main {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .el-table{
      overflow-y: auto;
    }
  }

.content-page {
  margin-top: 10px;
  height: 40px;
  display: flex;
  justify-content: flex-end;
}

.el-link {
  margin-right: 10px;
}
</style>
